/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146);
}

.owl-item {
  padding: 10px 20px !important;
}

.btn-primary:focus {
  background-color: #4fbfa8 !important;
  border-color: #4fbfa8 !important;
  box-shadow: 0 0 0 0.25rem rgba(96, 182, 156, 0.5);
}

#payment-form {
  margin: 0 auto !important;
}
#payment-form iframe {
  min-height: 610px !important;
}

@media screen and (max-width: 420px) {
  #payment-form iframe {
    min-height: 500px !important;
  }
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #4fbfa8;
  border-color: #4fbfa8;
}

.btn-check:focus + .btn-outline-primary, .btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: none !important;
}